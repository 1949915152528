import { Link } from '@remix-run/react'

const officeMapInfoLinks = [
  { href: '/faq', text: 'よくある質問' },
  { href: '/contact', text: 'お問い合わせ' },
  { href: 'https://media.officemap.jp/', text: 'オフィスマップメディア' },
  { href: '/privacy-policy', text: 'プライバシーポリシー' },
  { href: '/terms', text: '利用規約' },
  {
    href: 'https://trancereal.co.jp',
    rel: 'noreferrer',
    target: '_blank',
    text: '運営会社',
  },
]

const BottomNavigation = () => {
  return (
    <nav className="flex w-full flex-col items-center bg-[#EAF7F8] px-8 py-12">
      <div className="flex w-full max-w-[1280px] flex-col items-center md:flex-row md:items-center md:justify-center md:gap-x-60">
        <div className="flex w-[290px] flex-col items-start md:-mt-4 md:ml-[100px]">
          <div className="flex items-center gap-x-3">
            <img
              alt="footer-logo"
              aria-label="footer-logo"
              className="h-[130px] w-[130px]"
              src="/logo.svg"
            />
            <h2
              aria-label="footer-logo-title"
              className="min-w-[143px] text-[11px] font-bold leading-tight text-brand"
            >
              シェアオフィス
              <br />
              レンタルオフィス検索サイト
            </h2>
          </div>
          <span className="-mt-8 text-xs text-brand">
            Copyright © officeMAP. All Rights Reserved.
          </span>
        </div>

        <div className="flex flex-col justify-center px-2 pt-8 md:pt-0">
          <h3 className="mb-2 text-[14px] font-semibold text-gray-700">
            officeMAPに関する情報
          </h3>
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-wrap items-center">
              {officeMapInfoLinks.map((link, index) => (
                <div className="flex items-center py-2" key={link.href}>
                  {link.target ? (
                    <a
                      className="text-sm text-[#3672CD] hover:underline"
                      href={link.href}
                      rel={link.rel}
                      target={link.target}
                    >
                      {link.text}
                    </a>
                  ) : (
                    <Link
                      className="text-sm text-[#3672CD] hover:underline"
                      to={link.href}
                    >
                      {link.text}
                    </Link>
                  )}
                  {index < 5 && (
                    <div className="mx-4 h-4 border-r border-gray-300" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export const TopFooter = () => {
  return (
    <footer
      aria-label="footer"
      className="flex w-full flex-col justify-between gap-y-4 border-t border-grayBorder bg-white text-sm"
    >
      <BottomNavigation />
    </footer>
  )
}
